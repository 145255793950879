<template>
  <div>
    <div style="margin-top:5%;" id="inputSlotInfo" v-if="isCandidateType=='Protege'">
      <v-col style="max-height: 100%;border: 1px solid black;">
        <p>List of latest 10 slots</p>
        <v-list >
        <template >
          <v-list-item
            v-for="slot, index in slotsList"
            :key="index"
          >
            <v-list-item-content style="text-align:left;">
              <v-list-item-title :id="'slot'+index">{{index + 1}}. &nbsp;&nbsp; <span style="font-size:18px; cursor:pointer;" @click="getSlotsInfo(slot)">
                {{ slot["slot_title"] }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <v-btn id="inputGoToWorkbook" @click="gotoWorkbookFn(slot)"><v-icon>mdi-chevron-double-right</v-icon></v-btn>
              </span></v-list-item-title>
              <!-- Shouldnt this be handled by css instead of a ton of nbsps? -->
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>   
      </v-col>
      <v-col style="max-height: 100%;border: 1px solid black;">
        <p>Selected Slot Info</p>
        <div v-if="getSlotsInfo" style="color:black;text-align: left; font-size: 20px;">
          <p id="inputSlotTitle" v-if="slot_title"><span style="color:red;">Title : </span> {{slot_title}}</p>
          <p id="inputSlotTime" v-if="start_time"> <span style="color:red;">Start_Time : </span>  {{start_time}}</p>
          <p id="inputSlotId" v-if="slot_id"><span style="color:red;">Slot_id : </span>{{slot_id}}</p>
          <p id="inputSlotProposerName" v-if="proposer_name"><span style="color:red;">proposer_name : </span> {{proposer_name}}</p>
          <p id="inputSlotCandidateType" v-if="candidate_type"><span style="color:red;">candidate_type : </span> {{candidate_type}}</p>
        </div>
      </v-col>
    </div>
    <div v-if="isCandidateType=='Tutor'">
      <h1 style="color:red;">Note : candidate type is invalid</h1>
    </div>
  </div>
</template>

<script>
import AppConfig from '../../config.js';
import axios from 'axios';
//import workbook_wid from './workbook_id.vue'
  export default {
    name: 'HelloWorld',
    components:{
      //workbook_wid
    },
    data(){
      return{
        slotsList:[],
        UI_Base_URL: AppConfig.UI_Base_URL,
        work_api_Base_URL: AppConfig.Work_API_Base_URL,
        slot_title:"",
        start_time:"",
        slot_id:"",
        proposer_name:"",
        candidate_type:"",
        isCandidateType:"",
        //route_wid: "",
        //goto_workbook: false,
      }
    },
    async beforeMount() {
      await this.getSlotsFn();
    },
    methods:{
      async getSlotsFn(){
        try{
          /*let resp = await axios({
              method: "get",
              url:this.work_api_Base_URL + "/v/me/get_upcoming_slots",
          })*/
          let resp = await axios.get(this.work_api_Base_URL + "/v/me/get_upcoming_slots")
          console.log("response from get_upcoming_slots", resp)
          this.slotsList = resp.data
          this.isCandidateType=resp.data[0].candidate_type
          console.log("--isCandidateType",this.isCandidateType);
        }catch(error){
          console.log("error in resp of get_upcoming_slots is:", error.response)
          this.showRespMsg = true
          this.respMsg = error.response.headers["message"]
        }
      },
      getSlotsInfo(slot){
        console.log("-view slot info",slot);
        this.slot_title=slot.slot_title
        this.start_time=slot.start_time
        this.slot_id=slot.slot_id
        this.proposer_name=slot.proposer_name
        this.candidate_type=slot.candidate_type
        console.log("---1--",this.slot_title);
      },
      gotoWorkbookFn(slot){
        // TODO: if user is Tutor or Protege in slot
        let workbookURL = ""
        if(slot["candidate_type"]==="Protege"){//For now just a placeholder
          workbookURL = this.UI_Base_URL + '/protege/' + slot["slot_participant_id"]
        }
        else{
          workbookURL = this.UI_Base_URL + '/tutor/' + slot["slot_id"]
        }
        window.location.href = workbookURL
      },
    }
  }
</script>
<style>
#inputSlotInfo{
  margin-left:10px;
  margin-right: 10px;
  border:1px solid red; 
  height:200%;
  display: flex;
}
</style>
